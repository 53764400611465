import React, { useState, useEffect } from 'react';
import { apiCall } from '../utils/api';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Year({ onSelectSeason }) {
    const [seasons, setSeasons] = useState([]);
    const [currentDecade, setCurrentDecade] = useState(null);
    const [decadeGroups, setDecadeGroups] = useState({});

    useEffect(() => {
        apiCall('get_seasons')
            .then(seasonsData => {
                const seasonsArray = Object.values(seasonsData.data);
                setSeasons(seasonsArray);

                // Group seasons by decade
                const groups = seasonsArray.reduce((acc, season) => {
                    const year = parseInt(season.gp_season_name);
                    const decade = Math.floor(year / 10) * 10;
                    const key = year < 2000 ? String(decade).slice(-2) : decade;
                    
                    if (!acc[key]) acc[key] = [];
                    acc[key].push(season);
                    
                    return acc;
                }, {});
                setDecadeGroups(groups);

                // Set current decade and select current season
                const currentYear = new Date().getFullYear();
                const currentDecade = Math.floor(currentYear / 10) * 10;
                setCurrentDecade(currentDecade);

                const currentSeason = seasonsArray.find(season => 
                    season.gp_season_name === currentYear.toString()
                );
                if (currentSeason) {
                    onSelectSeason(currentSeason);
                }
            })
            .catch(error => {
                console.error('Error fetching seasons:', error);
            });
    }, []);

    const handleSeasonSelect = (season) => {
        onSelectSeason(season);
    };

    return (
        <Navbar expand="xxl" className="navbar navbar-expand-lg navbar-dark">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {decadeGroups[currentDecade] && decadeGroups[currentDecade]
                        .sort((a, b) => b.gp_season_name - a.gp_season_name)
                        .map((season, index) => (
                            <Nav.Link 
                                key={index} 
                                className="link"
                                onClick={() => handleSeasonSelect(season)}
                            >
                                {season.gp_season_name}
                            </Nav.Link>
                        ))
                    }

                    {Object.entries(decadeGroups)
                        .filter(([decade]) => parseInt(decade) !== currentDecade)
                        .sort((a, b) => b[0] - a[0])
                        .map(([decade, decadeSeasons]) => (
                            <NavDropdown 
                                key={decade} 
                                title={`${decade < 2000 ? '':'Années '}${decade}${decade < 2000 ? '\'':''}`} 
                                id={`decade-${decade}-dropdown`}
                            >
                                {decadeSeasons
                                    .sort((a, b) => b.gp_season_name - a.gp_season_name)
                                    .map((season, index) => (
                                        <NavDropdown.Item 
                                            key={index} 
                                            onClick={() => handleSeasonSelect(season)}
                                        >
                                            {season.gp_season_name}
                                        </NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                        ))
                    }
                </Nav>
                <Nav>
                    <Nav.Link href="?page=api" target="_blank">API</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Year;