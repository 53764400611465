import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import './RiderModal.scss';
import { withTranslation } from 'react-i18next';

function RiderModal({ rider_datas, seasonId, seasonName, modalRef, t }) {
  const [rider, setRider] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({
    flag: false,
    rider: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRider(rider_datas);
        let myModal = new Modal(modalRef.current);
        myModal.show();
        myModal._element.addEventListener('hidden.bs.modal', handleModalHidden);
      } catch (error) {
        console.error('Error fetching rider details:', error);
      }
    };

    if (rider_datas) {
      fetchData();
      // Reset image states when rider changes
      setImageLoaded({ flag: false, rider: false });
    }

    return () => {
      let myModal = new Modal(modalRef.current);
      myModal._element.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [rider_datas, seasonId]);

  const handleModalHidden = () => {
    setRider(null);
    setImageLoaded({ flag: false, rider: false });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const checkImage = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    if (rider) {
      // Check flag image
      const flagUrl = `https://flagcdn.com/56x42/${rider.gp_country_iso_code}.png`;
      checkImage(flagUrl).then((exists) => {
        setImageLoaded(prev => ({ ...prev, flag: exists }));
      });

      // Check rider image
      const riderUrl = `https://gp.anthonybois.fr/img/pilotes/${seasonName}/${String(rider.gp_riders_id).padStart(2, '0')}.png`;
      checkImage(riderUrl).then((exists) => {
        setImageLoaded(prev => ({ ...prev, rider: exists }));
      });
    }
  }, [rider, seasonName]);

  return (
    <div className="modal fade" id="riderModal" tabIndex="-1" aria-labelledby="riderModalLabel" aria-hidden="true" ref={modalRef}>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        {rider && (
          <div className="modal-content rounded-4 shadow-lg">
            <div className="modal-header border-bottom-0 pb-0">
              <div className="d-flex align-items-center">
                {imageLoaded.flag ? (
                  <img 
                    src={`https://flagcdn.com/56x42/${rider.gp_country_iso_code}.png`}
                    alt={rider.gp_country_iso_code}
                    className="me-3 rounded flag-img"
                  />
                ) : (
                  <span className="flag-placeholder me-3">{rider.gp_country_iso_code}</span>
                )}
                <h4 className="modal-title fs-3 fw-bold" id="riderModalLabel">
                  {rider.gp_riders_firstname} {rider.gp_riders_name}
                </h4>
              </div>
              <button 
                type="button" 
                className="btn-close position-absolute top-0 end-0 m-3" 
                data-bs-dismiss="modal" 
                aria-label={t('Close')}
              ></button>
            </div>
            <div className="modal-body pt-0">
              <div className="row">
                <div className="col-5 position-relative">
                  <div className="rider-img-container position-sticky top-0">
                    {imageLoaded.rider && (
                      <img 
                        className="rider-img w-100 rounded-3" 
                        src={`https://gp.anthonybois.fr/img/pilotes/${seasonName}/${String(rider.gp_riders_id).padStart(2, '0')}.png`}
                        alt={`${rider.gp_riders_firstname} ${rider.gp_riders_name}`}
                      />
                    )}
                    <div className="gradient-overlay rounded-3"></div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="rider-details">
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="detail-card bg-light p-3 rounded-3">
                          <h6 className="text-title mb-2 fw-bold">{t('Team')}</h6>
                          <p className="mb-0 text-desc">{rider.gp_team_name}</p>
                          {rider.gp_team_factory === 1 && (
                            <span className="badge bg-primary mt-2">{t('Factory Team')}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="detail-card bg-light p-3 rounded-3">
                          <h6 className="text-title mb-2 fw-bold">{t('BIKE')}</h6>
                          <p className="mb-0 text-desc">{rider.gp_bike_name}</p>
                        </div>
                      </div>
                      {rider.gp_riders_birth_date !== '0000-00-00' && (
                        <div className="col-6">
                          <div className="detail-card bg-light p-3 rounded-3">
                            <h6 className="text-title fw-bold mb-2">{t('Age')}</h6>
                            <p className="mb-0 text-desc">
                              {calculateAge(rider.gp_riders_birth_date)} {t('years')}
                            </p>
                            <small className="text-title">
                              {new Date(rider.gp_riders_birth_date).toLocaleDateString()}
                            </small>
                          </div>
                        </div>
                      )}
                      <div className="col-6">
                        <div className="detail-card bg-light p-3 rounded-3">
                          <h6 className="text-title mb-2">{t('Championship')}</h6>
                          <div className="d-flex justify-content-between">
                            <div>
                              <small className="text-title fw-bold">{t('Points')}</small>
                              <p className="mb-0 text-desc">{rider.points}</p>
                            </div>
                            <div>
                              <small className="text-title fw-bold">{t('Position')}</small>
                              <p className="mb-0 text-desc">{rider.position}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withTranslation('riderModal')(RiderModal);