import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { apiCall } from '../utils/api';
import { withTranslation } from 'react-i18next';
import './Records.scss';

class Records extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsData: {
        victories: { endpoint: 'get_all_victories', data: [], title: 'Number of victory', valueKey: 'nb_victory' },
        points: { endpoint: 'get_all_points', data: [], title: 'Number of points', valueKey: 'nb_points' },
        podiums: { endpoint: 'get_all_podiums', data: [], title: 'Number of podium', valueKey: 'nb_victory' },
        championships: { endpoint: 'get_all_winners', data: [], title: 'Number of championships won', valueKey: 'championships_won' }
      }
    };
  }

  componentDidMount() {
    this.fetchAllRecords();
  }

  fetchAllRecords = () => {
    const { recordsData } = this.state;
    
    const apiPromises = Object.entries(recordsData).map(([key, record]) => {
      return apiCall(record.endpoint)
        .then(response => {
          const data = Object.values(response.data);
          return { key, data };
        })
        .catch(error => {
          console.error(`Error fetching ${key}:`, error);
          return { key, data: [] };
        });
    });
    
    Promise.all(apiPromises)
      .then(results => {
        const updatedRecordsData = { ...recordsData };
        
        results.forEach(({ key, data }) => {
          updatedRecordsData[key].data = data;
        });
        
        this.setState({ recordsData: updatedRecordsData });
      });
  };

  renderTable = (recordKey, recordData) => {
    const { t } = this.props;
    
    return (
      <div className="col-6" key={recordKey}>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th scope="col">{t('Rider')}</th>
                <th scope="col">{t(recordData.title)}</th>
              </tr>
            </thead>
            <tbody>
              {recordData.data.map((item, index) => (
                <tr key={`${recordKey}-${item.rider_name}-${index}`} className={`cell-${index+1}`}>
                  <td className="text-end">
                    {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : <b>{index+1}</b>}
                  </td>
                  <td>{item.rider_name}</td>
                  <td>{item[recordData.valueKey]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    const { recordsData } = this.state;

    return (
      <div className="container">
        <div className="row stats">
          {Object.entries(recordsData).map(([key, record]) => 
            this.renderTable(key, record)
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('record')(Records);