import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Welcome from './components/Welcome';
import Riders from './components/Riders';
import Agenda from './components/Agenda';
import Evolution from './components/Evolution';
import Menu from './components/Menu';
import Year from './components/Year';
import Api from './components/Api';
import Records from './components/Records';
import { apiCall } from './utils/api';
import { useTranslation, withTranslation } from 'react-i18next';

function App() {
  const [selectedSeason, setSelectedSeason] = useState(0);
  const [allSeasons, setAllSeasons] = useState([]);
  
  useEffect(() => {
    apiCall('get_seasons')
      .then(seasonsData => {
        setAllSeasons(seasonsData.data);
      })
      .catch(error => {
        console.error('Error fetching seasons:', error);
      });
  }, []);
  
  const handleSeasonSelect = (season) => {
    setSelectedSeason(season);
  };
  
  const params = new URLSearchParams(window.location.search);
  
  return (
    <Router>
      <div className="container">
        {(params.get('page') === 'riders' || params.get('page') === 'welcome' || !params.get('page')) && (
          <Year onSelectSeason={handleSeasonSelect} />
        )}
        <HeaderWithTranslation />
        {(params.get('page') !== 'api') && (
          <Menu />
        )}
        {getPageContent(selectedSeason, allSeasons)}
      </div>
    </Router>
  );
}

function Header({ t }) {
  const location = useLocation();

  const getTitle = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');

    switch (page) {
      case 'riders':
        return 'Riders';
      case 'agenda':
        return 'Calendar';
      case 'evolution':
        return 'Evolution';
      case 'records':
        return 'Records';
      case 'api':
        return 'Api';
      default:
        return 'Results';
    }
  };

  React.useEffect(() => {
    document.title = t(getTitle());
  }, [location, t]);

  return <h1>{t(getTitle())}</h1>;
}

const HeaderWithTranslation = withTranslation('app')(Header);

function getPageContent(selectedSeason, allSeasons) {
  const params = new URLSearchParams(window.location.search);
  const page = params.get('page');

  switch (page) {
    case 'riders':
      return <Riders season={selectedSeason} allSeasons={allSeasons} />;
    case 'agenda':
      return <Agenda />;
    case 'evolution':
      return <Evolution />;
    case 'records':
      return <Records />;
    case 'api':
      return <Api />;
    default:
      return <Welcome season={selectedSeason} allSeasons={allSeasons} />;
  }
}

export default withTranslation('app')(App);