import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { apiCall } from '../utils/api';
import RiderModal from './RiderModal';
import { withTranslation } from 'react-i18next';
import './Welcome.scss';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      riders: [],
      teams: [],
      selectedRider: null,
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.state.selectedRider = 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.fetchData();
    }
  }

  fetchData() {
    const { season } = this.props;
    if(!season.gp_season_id) return
    Promise.all([
      apiCall(`get_all_resultats&season_id=${season.gp_season_id}`),
      apiCall(`get_datas_by_riders&season_id=${season.gp_season_id}`),
      apiCall(`get_datas_by_teams&season_id=${season.gp_season_id}`)
    ])
      .then(([resultsData, ridersData, teamsData]) => {
        this.setState({
          results: Object.values(resultsData.data),
          riders: Object.values(ridersData.data),
          teams: Object.values(teamsData.data),
        }, () => {
          if (this.state.results.length > 0 && this.state.riders.length > 0) {
            this.createChart();
          }
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  createChart() {
    const { riders, results } = this.state;
    let series = [];
    let y_axe = [];
    // Création des séries de données pour chaque coureur
    for (let id in riders) {
      let rider = riders[id];
      let rider_id = rider.gp_riders_id;
      let data = [];
      let racePoints = 0;
  
      // Ajout des résultats du coureur à chaque course
      for (let id in results) {
        let raceResults = results[id];
        let points = 0;
        for (let index in raceResults) {
          let result = raceResults[index];
          if (result.rider_id == rider_id) {
            points = parseInt(result.points);
            racePoints += points;
            break; // Sortir de la boucle interne une fois que le pilote est trouvé
          }
        }
        data.push(racePoints);
      }
  
      series.push({
        name: rider.gp_riders_firstname + ' ' + rider.gp_riders_name,
        data: data,
        visible: false,
        finalPoints: racePoints
      });
    }
  
    // Création de l'axe y
    for (let id in results) {
      let date = results[id].gp_races_date
      let raceResults = results[id];
      y_axe.push(date + ' (' + raceResults.type + ')');
    }
  
    // Tri des séries en fonction des points finaux
    series.sort((a, b) => (b.finalPoints || 0) - (a.finalPoints || 0));

    // Affichage des 5 premières séries
    for (let j = 0; j < 5 && j < series.length; j++) {
      series[j].visible = true;
    }
  
    // Création du graphique
    Highcharts.chart('chart-c', {
        colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'
        ],

        series: series,
        responsive: {
          rules: [{
              condition: {
              maxWidth: 1000
              },
              chartOptions: {
                legend: {
                  maxHeight:75,
                }
              }
          }]
        },
        chart: {
            zoomType: 'x',
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                stops: [
                    [0, '#2a2a2b'],
                    [1, '#3e3e40']
                ]
            },
            style: {
                fontFamily: '\'Unica One\', sans-serif'
            },
            plotBorderColor: '#606063'
        },
        title: {
            text: 'Classement MotoGP',
            style: {
                color: '#E0E0E3',
                textTransform: 'uppercase',
                fontSize: '20px'
            }
        },
        subtitle: {
            style: {
                color: '#E0E0E3',
                textTransform: 'uppercase'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        xAxis: {
            categories: y_axe,
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            title: {
                text: 'Courses',
                style: {
                    color: '#A0A0A3'
                }
            }
        },
        yAxis: {
            gridLineColor: '#707073',
            labels: {
                style: {
                    color: '#E0E0E3'
                }
            },
            lineColor: '#707073',
            minorGridLineColor: '#505053',
            tickColor: '#707073',
            tickWidth: 1,
            title: {
                text: 'Points',
                style: {
                    color: '#A0A0A3'
                }
            }
        },
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            style: {
                color: '#F0F0F0'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: '#F0F0F3',
                    style: {
                        fontSize: '13px'
                    }
                },
                marker: {
                    lineColor: '#333',
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            },
            boxplot: {
                fillColor: '#505053'
            },
            candlestick: {
                lineColor: 'white'
            },
            errorbar: {
                color: 'white'
            }
        },
        legend: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            itemStyle: {
                color: '#E0E0E3'
            },
            itemHoverStyle: {
                color: '#FFF'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0'
                }
            }
        },
        credits: {
            style: {
                color: '#666'
            }
        },
        labels: {
            style: {
                color: '#707073'
            }
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: '#F0F0F3'
            },
            activeDataLabelStyle: {
                color: '#F0F0F3'
            }
        },
        navigation: {
            buttonOptions: {
                symbolStroke: '#DDDDDD',
                theme: {
                    fill: '#505053'
                }
            }
        },
        // scroll charts
        rangeSelector: {
            buttonTheme: {
                fill: '#505053',
                stroke: '#000000',
                style: {
                    color: '#CCC'
                },
                states: {
                    hover: {
                        fill: '#707073',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    },
                    select: {
                        fill: '#000003',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    }
                }
            },
            inputBoxBorderColor: '#505053',
            inputStyle: {
                backgroundColor: '#333',
                color: 'silver'
            },
            labelStyle: {
                color: 'silver'
            }
        },
        navigator: {
            handles: {
                backgroundColor: '#666',
                borderColor: '#AAA'
            },
            outlineColor: '#CCC',
            maskFill: 'rgba(255,255,255,0.1)',
            series: {
                color: '#7798BF',
                lineColor: '#A6C7ED'
            },
            xAxis: {
                gridLineColor: '#505053'
            }
        },
        scrollbar: {
            barBackgroundColor: '#808083',
            barBorderColor: '#808083',
            buttonArrowColor: '#CCC',
            buttonBackgroundColor: '#606063',
            buttonBorderColor: '#606063',
            rifleColor: '#FFF',
            trackBackgroundColor: '#404043',
            trackBorderColor: '#404043'
        }
    });  
  };

  handleRiderClick = (riderId) => {
    this.setState({ selectedRider: riderId });
  };

  render() {
    const { riders, teams, selectedRider } = this.state;
    const { season, allSeasons, t } = this.props;
    if(!season || allSeasons.length < 1) {
      return
    }
   
    const GP = allSeasons[season.gp_season_name].gp_season_type === 'MotoGP';
    return (
      <div>
        <div id="chart-c" />
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t('Rider')}</th>
                  <th scope="col"></th>
                  <th scope="col">{t('Points')}</th>
                </tr>
              </thead>
              <tbody>
                {riders.map(data => (
                  <tr key={data.gp_riders_id}>
                    <td>
                      {data.gp_team_color.map(gp_team_color => (
                        <b style={{ backgroundColor: `#${gp_team_color}` }}>&nbsp;&nbsp;</b>
                      ))}
                      <a className="link" onClick={() => this.handleRiderClick(data)}>
                        {GP?data.gp_riders_id:''} {data.gp_riders_firstname} {data.gp_riders_name} (<i>{data.gp_team_name}</i>)
                      </a>
                    </td>
                    <td>
                      <img src={`https://flagcdn.com/24x18/${data.gp_country_iso_code}.png`} alt={data.gp_country_iso_code} />
                    </td>
                    <td>{data.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <RiderModal rider_datas={selectedRider} seasonId={season.gp_season_id} seasonName={season.gp_season_name} modalRef={this.modalRef} />
          </div>

          <div className="col-md-4 col-sm-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t('Team')}</th>
                  <th scope="col"></th>
                  <th scope="col">{t('Points')}</th>
                </tr>
              </thead>
              <tbody>
                {teams.map(data => (
                  <tr key={data.gp_team_id}>
                    <td>
                      <span style={{ backgroundColor: `#${data.gp_team_color}` }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      {data.gp_team_name} (<i>{data.gp_bike_name}</i>)
                    </td>
                    <td>
                      <img src={`https://flagcdn.com/24x18/${data.gp_country_iso_code}.png`} alt={data.gp_country_iso_code} />
                    </td>
                    <td>{data.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Tableau des actualités */}
          <div className="col-md-4 col-sm-12">
            <iframe src="https://gp.anthonybois.fr/cron/actu.html" style={{ minHeight: '600px' }} width="100%" height="100%" frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('welcome')(Welcome);
